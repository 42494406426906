import Headroom from "headroom.js";

const header = document.querySelector("header");

/** @type {import("headroom.js").HeadroomOptions} */
const options = {
  offset: header.offsetHeight + 50,
};

const headroom = new Headroom(header, options);

headroom.init();
